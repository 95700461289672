<template>
  <section
    class="dashboard-view"
    :class="{ mobile: getIsMobile, 'is-open': isCollapse }"
  >
    <el-row>
      <el-col :span="24">
        <TopbarDefault @open:sidebar="sidebarOpen"></TopbarDefault>
      </el-col>
    </el-row>
    <template v-if="isHorMenu && !isMobile">
      <el-row>
        <el-col :span="24">
          <HorizontalMenu />
        </el-col>
      </el-row>
      <br /><br />
      <el-row type="flex" justify="center">
        <el-col :xl="24" :lg="24" :md="24">
          <div class="canvas">
            <!-- <el-link
              icon="el-icon-link"
              style="font-size: 24px; float: right; margin-top: 8vh"
              :underline="false"
              class="mt-"
              @click="openViewConfigPage"
            ></el-link> -->
            <main class="main-dashboard-view" id="MainLayout">
              <router-view :layout.sync="layout" />
            </main>
          </div>
        </el-col>
      </el-row>
    </template>
    <template v-else>
      <el-row type="flex" justify="center">
        <el-col :xl="24" :lg="24" :md="24">
          <div :class="!getIsOpen ? 'canvas' : 'offCanvas'">
            <!-- <el-link
              icon="el-icon-link"
              style="font-size: 24px; float: right; margin-top: 20px"
              :underline="false"
              class="mt-"
              @click="openViewConfigPage"
            ></el-link> -->
            <main
              class="main-dashboard-view main-dashboard-view-with-sidebar"
              id="MainLayout"
            >
              <router-view :layout.sync="layout" />
            </main>
          </div>
        </el-col>
      </el-row>
      <div class="bg-canvas" v-on:click="closeLeftSideBar()"></div>
      <SidebarDefault :open="sidebarStatus" class="left-side-bar" />
    </template>
    <div>
      <el-drawer
        title="Privacy Link"
        :visible.sync="copyModal"
        :direction="'rtl'"
        :with-header="true"
        :size="getIsMobile ? '50%' : '20%'"
      >
        <div style="padding: 15px">
          <span>Link Name</span>
          <el-input
            v-model="linkName"
            placeholder="Enter the Link name"
          ></el-input>
          <span>choose the link type</span>
          <el-card
            style="
              width: 100%;
              height: 50px;
              display: flex;
              align-items: center;
            "
          >
            <br />
            <el-radio-group v-model="link" style="margin-bottom: 20px">
              <el-row>
                <el-radio label="PUBLIC"></el-radio>
                <el-radio label="PRIVATE"></el-radio>
              </el-row>
            </el-radio-group>
          </el-card>
          <div style="margin-left: 30px" class="mt-1">
            <el-button
              type="danger"
              icon="el-icon-link"
              class="copy-btn"
              @click="generateLink()"
              :disabled="!linkName || !link"
            >
              Generate Link
            </el-button>
          </div>
          <div v-if="link == 'Public'"></div>
          <div v-if="inputFlag">
            <div style="display: flex" class="mt-1">
              <el-input
                size="mini"
                v-model="generatedLink"
                ref="privateLinkRef"
                v-on:focus="$event.target.select()"
              ></el-input>
              <el-button
                type="danger"
                icon="el-icon-copy-document"
                class="copy-btn"
                size="mini"
                @click="copyLinkToClipboard('privateLinkRef')"
              ></el-button>
            </div>
          </div>
          <div class="mt-1">
            <p style="margin-bottom: 8px; font-style: bold">Existing link</p>
            <div v-for="(link, index) in getIframeUrLinks" :key="index">
              <el-row class="mt-1">
                <el-col :span="10">
                  <p>
                    {{ getIframeUrLinks[index].linkName }}
                    <el-popover
                      placement="top-start"
                      trigger="hover"
                      :content="getIframeUrLinks[index].url_type"
                    >
                      <i class="el-icon-info" slot="reference"></i>
                    </el-popover>
                  </p>
                </el-col>
                <el-col :span="5" style="text-align: right">
                  <el-button
                    type="danger"
                    icon="el-icon-link"
                    class="copy-btn"
                    size="mini"
                    @click="generateExistLink(link)"
                    style="float: right; margin-left: 10px"
                  >
                  </el-button>
                </el-col>
                <el-col :span="5" style="text-align: right">
                  <el-switch
                    v-model="getIframeUrLinks[index].status"
                    :active-value="'ACTIVE'"
                    :inactive-value="'INACTIVE'"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="statusChanged(link)"
                  ></el-switch>
                </el-col>
                <el-col :span="4">
                  <el-link
                    style="float: right !important"
                    class="mr-1 ml-1"
                    type="danger"
                    :underline="false"
                    @click="onDelete(link)"
                    ><i class="el-icon-delete"></i>
                  </el-link>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import TopbarDefault from "../layouts/TopbarDefault";
import SidebarDefault from "../layouts/SidebarDefault";
import HorizontalMenu from "../layouts/HorizontalMenu";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import appConfig from "@/config/app";
export default {
  name: "home",
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    // this.$router.listen((newLocation) => {console.log(newLocation);})
    // console.log(this.$route);
    if (this.getIsMobile) {
      this.$store.commit("navigationOpen/setIsOpen", false, {
        root: true,
      });
    }
  },
  components: {
    TopbarDefault,
    SidebarDefault,
    HorizontalMenu,
  },
  mixins: [MobileRelatedHelper],
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
    ]),
    ...mapGetters("auth", [
      "getActiveWorkspace",
      "getAuthenticationDetails",
      "getAuthenticatedSSOToken",
      "getUserTypeList",
    ]),
    ...mapGetters("company", [
      "getContactLoginSlug",
      "getUpdateCompany",
      "getCompanyDetails",
    ]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken"]),
    ...mapGetters("iFrame", ["getIframeData", "getIframeUrLinks"]),
    isCollapse: {
      get() {
        return this.getIsOpen;
      },
      set(isCollapse) {
        this.$store.commit("navigationOpen/setIsOpen", isCollapse, {
          root: true,
        });
      },
    },
    getLabel() {
      return (userType) => {
        if (userType && userType.role) {
          return userType.role.title + " - " + userType.name;
        } else {
          return userType.name;
        }
      };
    },
  },
  async mounted() {
    await this.getCompanyInfo();
    this.getMenuType();
  },
  data() {
    return {
      layout: "div",
      offCanvas: true,
      sidebarStatus: false,
      windowWidth: 1500,
      isHorMenu: false,
      copyModal: false,
      getlink: "",
      activeTab: "first",
      publicLink: "",
      privateLink: "",
      isChecked: false,
      link: "",
      dashboard_role: [],
      inputFlag: false,
      generatedLink: "",
      existedLink: "",
      comparedUrlData: {},
      linkName: "",
      toggle: true,
    };
  },
  methods: {
    async getCompanyInfo() {
      // this.loading = true;
      try {
        if (
          this.getActiveWorkspace?.company_id !== this.getCompanyDetails?._id ||
          !this.getCompanyDetails?._id
        ) {
          await this.$store.dispatch(
            "company/fetchCompany",
            this.getActiveWorkspace.company_id
          );
          if (this.getCompanyDetails) {
            this.loading = false;
            this.brandingInfo = { ...this.getCompanyDetails };
          }
        } else {
          this.brandingInfo = { ...this.getCompanyDetails };
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    sidebarOpen(status) {
      this.sidebarStatus = status;
    },
    closeLeftSideBar() {
      this.$store.commit("navigationOpen/setIsOpen", false, {
        root: true,
      });
    },
    getMenuType() {
      if (
        this.getCompanyDetails &&
        this.getCompanyDetails.menuType &&
        this.getCompanyDetails.menuType == "Horizontal"
      ) {
        this.isHorMenu = true;
      } else {
        this.isHormenu = false;
      }
    },
    async openViewConfigPage() {
      let url_key = this.generateOutputFromUrl(this.$route.fullPath, [
        "key",
        "page",
        "pageSize",
      ]);
      let params = {
        url_key: url_key,
        company_id: this.getActiveWorkspace.company_id,
      };
      await this.$store.dispatch("iFrame/fetchIframeUrlLinks", params);
      this.copyModal = true;
      this.generatedLink = "";
      this.link = "";
      this.linkName = "";
    },
    generateOutputFromUrl(url, excludedKeys = []) {
      let urlParts = url.split("?");
      let path = urlParts[0];
      let queryParams = urlParts.length > 1 ? urlParts[1].split("&") : [];
      let pathSegments = path.split("/");
      let keyValuePairs = {};
      queryParams.forEach((param) => {
        let [key, value] = param.split("=");
        if (!excludedKeys.includes(key)) {
          keyValuePairs[key] = value;
        }
      });
      let output = "";

      pathSegments.forEach((segment, index) => {
        if (index !== 0 && index !== 1) {
          output += `#${segment}`;
        } else {
          output += segment;
        }
      });
      for (const [key, value] of Object.entries(keyValuePairs)) {
        output += `#${key}=${value}`;
      }
      return output;
    },
    generateExistLink(item) {
      let url = appConfig.APP_URL;
      this.existedLink = `${url}/if/${item._id}${item.path}`;
      const tempInput = document.createElement("textarea");
      tempInput.value = this.existedLink;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$message.success("Link copied to clipboard");
    },
    async statusChanged(link) {
      let params = {
        id: link._id,
        status: link.status,
      };
      await this.$store.dispatch("iFrame/updateIframeUrlStatus", params);
    },
    onDelete(link) {
      this.$confirm("Are you sure to delete the link?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteURL(link);
      });
    },
    async deleteURL(link) {
      let params = {
        id: link._id,
      };
      await this.$store.dispatch("iFrame/deleteIframeUrl", params);
      this.openViewConfigPage();
    },
    copyLinkToClipboard(refName) {
      this.$refs[refName].focus();
      document.execCommand("copy");
      this.$message.success("Link copied to clipboard");
    },
    async generateLink() {
      if (this.getAuthenticatedSSOToken) {
        if (this.linkName && this.link) {
          let queryParams =
            Object.keys(this.$route.query).length > 0
              ? `?${new URLSearchParams(this.$route.query).toString()}`
              : "";
          let path = this.$route.path + queryParams;
          let url_key = this.generateOutputFromUrl(this.$route.fullPath, [
            "key",
            "page",
            "pageSize",
          ]);
          let payload = {
            url_type: this.link,
            token: this.getAuthenticatedSSOToken,
            path: path,
            url_key: url_key,
            linkName: this.linkName,
          };
          this.inputFlag = true;
          await this.$store.dispatch("iFrame/addIframe", payload);
          let url = appConfig.APP_URL;
          this.generatedLink = `${url}/${this.getIframeData.link}`;
        } else {
          this.$message.error(
            "Please enter the Link name and choose the link type."
          );
        }
      } else {
        this.$message.error(
          "To generate a link, please log in through Nimble SSO."
        );
      }
    },
  },
  watch: {
    link(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.generatedLink = "";
      }
    },
  },
};
</script>

<style lang="scss">
.dashboard-view {
  &.mobile {
    &.is-open {
      // padding-left: 100px;
      .bg-canvas {
        display: block;
      }
    }
    .main-dashboard-view {
      padding-left: 30px;
      // padding-right: 30px;
    }
    .left-side-bar {
      position: absolute;
      z-index: 11000;
    }
    .offCanvas {
      margin-left: 0px;
      // background: rgba(0, 0, 0, .025);
    }
    .bg-canvas {
      background: rgba(0, 0, 0, 0.5);
      z-index: 10000;
      width: 100vw;
      height: 100vh;
      position: absolute;
      border: 1px solid red;
      top: 0;
      left: 0;
      display: none;
    }
  }
}
.dashboard-view {
  background: #fafafa;
  min-height: 100vh;
  z-index: 999;
  background-color: var(--application-background-color);
  // min-height: calc(100vh - 68px);
  .main-dashboard-view {
    margin-top: 0px;
    padding-top: 10px !important;
    padding-left: 100px;
    padding-right: 30px;
  }
  .main-dashboard-view-with-sidebar {
    margin-top: 5vh !important;
    padding-top: 30px !important;
    padding-left: 100px !important;
    padding-right: 30px !important;
  }
  .edit-new-template-view {
    padding-left: 0;
    height: 90vh;
  }
  .canvas {
    transition: all 0.2s ease-in-out;
  }
  .offCanvas {
    margin-left: 150px;
    transition: all 0.2s ease-in-out;
  }
  .icon-link {
    font-size: 24px;
    float: right;
  }
  .el-input--mini .el-input__inner {
    width: 95%;
  }
}
.el-drawer__wrapper .el-drawer__header {
  background-color: #1b487e;
  border-bottom: none;
  padding-bottom: 0.2em;
  padding-top: 5px;
  color: #ffffff;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  letter-spacing: 0.5px;
}
.el-drawer__header {
  margin-bottom: 1px;
  padding: 20px 20px 0;
}
</style>
